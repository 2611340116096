var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    [
      _c("table-component", {
        ref: "currentTable",
        attrs: {
          "items-repo": _vm.feeSchedulePricingRepo,
          "load-params": {
            FeeScheduleId: _vm.feeScheduleId,
            FeeScheduleVersionId: _vm.feeScheduleVersionId,
            PricingForDate: _vm.currentDate,
          },
          "is-data-from-model": false,
          "is-add-edit-sidbar": true,
          "get-by-id-on-edit-filter": function () {
            return true
          },
          fields: _vm.fields,
        },
        scopedSlots: _vm._u([
          {
            key: "Add",
            fn: function () {
              return [
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.showPricingFor,
                            expression: "showPricingFor",
                          },
                        ],
                      },
                      [
                        _c("b-form-datepicker", {
                          attrs: { id: "sortBySelect", size: "sm" },
                          on: { input: _vm.onDateUpdate },
                          model: {
                            value: _vm.currentDate,
                            callback: function ($$v) {
                              _vm.currentDate = $$v
                            },
                            expression: "currentDate",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      {
                        staticStyle: {
                          "padding-top": "7px",
                          "padding-bottom": "7px",
                        },
                      },
                      [
                        _c("b", [_vm._v("Version:")]),
                        _vm._v(
                          " " +
                            _vm._s(_vm.currnetDataVersion.versionNumber) +
                            "      \n                "
                        ),
                        _c("b", [_vm._v("Start:")]),
                        _vm._v(
                          " " +
                            _vm._s(
                              new Date(
                                _vm.currnetDataVersion.dateStart
                              ).toLocaleDateString()
                            ) +
                            "      \n                "
                        ),
                        _c("b", [_vm._v("End:")]),
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.currnetDataVersion.dateEnd
                                ? new Date(
                                    _vm.currnetDataVersion.dateEnd
                                  ).toLocaleDateString()
                                : "N/A"
                            ) +
                            "  \n              "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "cell(pricingDetails)",
            fn: function (data) {
              return [
                _vm._l(data.value, function (item) {
                  return [
                    _c("div", [
                      _vm._v(
                        "\n              " +
                          _vm._s(item.modifier ? item.modifier.code : "NONE") +
                          "\n            "
                      ),
                    ]),
                  ]
                }),
              ]
            },
          },
          {
            key: "cell(pricingDetails.facilityPrice)",
            fn: function (data) {
              return [
                _vm._l(data.item.pricingDetails, function (item) {
                  return [
                    _c("div", [
                      _vm._v(
                        "\n              " +
                          _vm._s("$" + item.facilityPrice) +
                          "\n            "
                      ),
                    ]),
                  ]
                }),
              ]
            },
          },
          {
            key: "cell(pricingDetails.nonFacilityPrice)",
            fn: function (data) {
              return [
                _vm._l(data.item.pricingDetails, function (item) {
                  return [
                    _c("div", [
                      _vm._v(
                        "\n              " +
                          _vm._s("$" + item.nonFacilityPrice) +
                          "\n            "
                      ),
                    ]),
                  ]
                }),
              ]
            },
          },
        ]),
        model: {
          value: _vm.currentFeeSchedules,
          callback: function ($$v) {
            _vm.currentFeeSchedules = $$v
          },
          expression: "currentFeeSchedules",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }